/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom";

import Showcase from "../components/Showcase"
import '../components/slide.css'
import { Link } from "gatsby";

class FullpageWrapper extends React.Component {
  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }
  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }
  render() {
    return (
        <div className="Full">
        <a href="./"><div className="BackImage"></div></a>
        <div className="Tips01"><p>・<span>Horizontal Browsing</span>・iPhone 📱 ↩️</p></div>
     
            <div>
                        
            <div id="fullpage-wrapper">
            
              <div className="section section1">  
              <Link to="https://redgalaxy.netlify.app/" target="_blank">
                      <div className="LinkGroup">
                        <div className="LinkTitleContent">
                          <div className="LinkTitle">
                            <h3>Launch Project</h3>
                          </div>                    
                            <div className="Arrow"></div>
                        </div>
                      </div>
                    </Link>
                <Showcase 
                    
                    image01="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Red_Front.png"
                    image02="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Red_Back.jpg"
                    logo="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_red.png"
                    name="XiaoHongShu"

                    number="01/03"

                    title01="Mark My Life"
                    title02="Darkmode Design Proposal"

                    text01="As an APP that records the user's life community, Xiaohongshu focuses on the mining of user content production and the creation of community atmosphere."
                    text02="Optimizes the dark mode and Android platform device experience to provide a better quality content production experience."

                    link="Launch Project"
                    arrow = "https://yhmnin.oss-cn-beijing.aliyuncs.com/Button_Next_White.png"
                    
                    scroll = {require('../images/global/Button_Scroll Down.png')}
                    tips="Scroll Up"
                />                            
              </div>
              
                <div className="section section02">
                    <Link to="/Bytedance" target="_blank">
                      <div className="LinkGroup">
                        <div className="LinkTitleContent">
                          <div className="LinkTitle">
                            <h3>Launch Project</h3>
                          </div>                    
                            <div className="Arrow"></div>
                        </div>
                      </div>
                    </Link>
                    <Showcase 
                        
                        image01="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Bytedance_Front.png"
                        image02="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Bytedance_Back.png"
                        logo="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_bytedance.png"
                        name="Bytedance"

                        number="02/03"

                        title01="Smooth, collaborative audio"
                        title02="Video conferences"

                        text01="Feishu's audio/video conferencing is deeply integrated with Chat, Calendar and Docs to support high-quality audio/video conferences anytime, anywhere."
                        text02="The stable multi-party connection, easy-to-use livestreaming and powerful screen sharing make it easy to communicate and collaborate in real time."

                        link="Launch Project"
                        arrow = {require('../images/Red/Button_Next_White.png')}
                        
                        scroll = {require('../images/global/Button_Scroll Down.png')}

                        tips="Scroll Up"
                    />
                </div>
                <div className="section section03">
                    <Link to="/Smartisan" target="_blank">
                      <div className="LinkGroup">
                        <div className="LinkTitleContent">
                          <div className="LinkTitle">
                            <h3>Launch Project</h3>
                          </div>                    
                            <div className="Arrow"></div>
                        </div>
                      </div>
                    </Link>
                    <Showcase 
                        
                        image01="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Smartisan_Front.jpg"
                        image02="https://yhmnin.oss-cn-beijing.aliyuncs.com/Cover_Smartisan_Back.png"

                        logo="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_smartisan.png"
                        name="Bytedance"

                        number="03/03"

                        title01="Smartisan OS 6.6.6"
                        title02="Mobile Phone Application"

                        text01="Smartisan OS 6.6.6 is derived from the simple, efficient and beautiful Smartisan OS mobile phone operating system. It will bring you an unprecedented large-screen control experience, which is far more efficient than traditional desktop systems."
                        text02="Considering both efficiency and expandability, the design style is lightweight, the interactive way of sliding the operation bar is adjusted, and the picture quality is improved."

                        link="Launch Project"
                        arrow = {require('../images/Red/Button_Next_White.png')}
                        
                        
                    />
                </div>
             
            </div>
            </div>
       
      </div>
    );
  }
}


export default FullpageWrapper;
