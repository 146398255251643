import React from 'react'
import styled from 'styled-components'

const ShowcaseContent = styled.div`
    width:100vw;
    height:100vh;
    overflow:hidden;
    @media(max-width:640px){
        height:200vh;
    }
    
`
const InnerContent = styled.div`
    display:grid;
    grid-template-columns:repeat(2,1fr);
    background:var(--grey01);
    background-size:cover;
    margin:3vh;

    @media(max-width:640px){
        grid-template-columns:repeat(1,1fr);
    }
    `

const BackHome = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:2vw;
    height:2vw;
    border-radius:3.2vw;
    postion:absolute;
    margin:2vw;

    &:hover{
        transform:scale(1.2);
        transition:0.5s cubic-bezier(0.6,0.05,0.4,0.95)
    }

    @media(max-width:640px){
        width:6.4vw;
        height:6.4vw;
    }
`

/*左侧图片内容区域*/
const Combine_Imageview = styled.div`
    display:grid;
    grid-template-columns:repeat(10,5vw);
    grid-template-rows:repeat(10,9.4vh);
`

const Cover_Imageview_Front = styled.div`
    background: url(${props => props.image});
    background-size:cover;
    z-index:100;
    grid-column:5/10;
    grid-row:2/10;
    width:24vw;
    height:24vw;
    border-radius:24vw;
    
    animation:Move 2s cubic-bezier(.4,.05,0.6,.95) 0.1s infinite alternate;

    @media(max-width:640px){
        width:64vw;
        height:64vw;
    }

    @keyframes Move{
        0%{
            transform:translate3d(0, 0, 0) scale(1);
        }
        100%{
            transform:translate3d(-42px, -32px, 35px) scale(1.2);
        }
    }
    
`

const Cover_Imageview_Back = styled.div`
    background: url(${props => props.image});
    background-size:cover;
    grid-column:2/10;
    grid-row:4/10;
    width:24vw;
    height:24vw;
    border-radius:24vw;
    animation:Rotate 8s cubic-bezier(.4,.05,0.6,.95) 0.1s infinite alternate;
    
    @media(max-width:640px){
        width:64vw;
        height:64vw;
    }

    @keyframes Rotate{
        0%{
            transform:rotate(0deg)
        }
        100%{
            transform:rotate(360deg)
        }
    }
`

/*右侧文本内容区域*/
const ShowcaseDetails = styled.div`
    margin-left:5vw;
    align-self:center;
`

//*标题内容区域 *//


const ShowcaseInfo = styled.div`
    width:32vw;
    display:grid;
    grid-template-columns:repeat(2,1fr)
    
`

const CompanyInfo = styled.div`
    display:grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap:8px;
    justify:center;
    align-items:center;
    `

const CompanyLogo = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:2.4vw;
    height:1.2vw;
    justify-items:start;
    align-items:center;
    @media(max-width:640px){
        width:8vw;
        height:4vw
    }
`

const CompanyName = styled.h3`
    font-family: var(--header-font-family);
    font-size: var(--Body);
    color:var(--brown_dark);
    `

const NumberInfo = styled.div`
    display:grid;
    grid-template-columns:12vw auto;
    grid-gap:12px`

const Splitline = styled.div`
    width:12vw;
    height:1px;
    background:var(--grey03);
    align-self:center;
    justify-items:center;
    `

const Number = styled.h3`
    font-family: var(--header-font-family);
    font-size: var(--Body);
    color:var(--brown_dark);
`

//*文本内容区域 *//
const ShowcaseDescription = styled.div`
    width:32vw;

    @media(max-width:640px){
        width:80vw;
    }
`

const ShowcaseTitle = styled.div``

const Title01 = styled.h2`
    font-family: var(--header-font-family);
    font-size: var(--H1);
    font-weight: 800;
    color:var(--brown_dark);
    transform-origin: -100% 100%;
    margin:0;
    padding-top:3.2vh;

    `

const Title02 = styled.h2`
font-family: var(--header-font-family);
    font-size: var(--H1);
    font-weight: 800;
    color:var(--brown_dark);
    transform-origin: -100% 100%;
    margin:0;
    padding-bottom:3.2vh;
    `

const ShowcaseText01 = styled.h3`
    font-family: var(--header-font-family);
    font-size: var(--H3);
    font-weight: 600;
    color:var(--brown_dark);
    opacity:0.6;
    line-height:1.65;
`

const ShowcaseText02 = styled.h3`
    font-family: var(--header-font-family);
    font-size: var(--H3);
    font-weight: 600;
    color:var(--brown_dark);
    opacity:0.6;
    line-height:1.65;
    `

//*案例链接区域 *//

const ShowcaseLink = styled.div`
    margin-top:4.8vh;
    display:grid;
    grid-template-columns:8.8vw auto;
    align-items:center;
    
`

const LinkName = styled.h3`
    font-family: var(--header-font-family);
    font-size: var(--H3);
    font-weight: 600;
    color:var(--brown_dark);
`

const LinkImage = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:3vw;
    height:3vw;
    justify-self:center;
    align-self:center;
`

//*滚动按钮 *//
const Scroll = styled.div`
    
    right:7.2vh;
    display:grid;
    grid-auto-rows:repeat(2,1fr)
`
const ScrollDown = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:3.2vw;
    height:3.2vw;
    bottom:7.2vh;
    right:7.2vh;
    position:absolute;
    animation: Move 1.2s cubic-bezier(.4,.05,0.6,.95) 0.1s infinite alternate;
    

    @keyframes Move{
        0%{
            transform:translateY(20px)
        }
        100%{
            transform:translateY(0px)
        }
    }
`
const ScrollTips = styled.p`
    font-family: var(--body-font-family);
    font-size: var(--Addtion);
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 80vh;
    right: 7.2vh;
    z-index: 100;
    transform: rotate(90deg);
`


const Showcase = props => (
    <div>     
        <ShowcaseContent>
            <InnerContent>
            <Combine_Imageview>
                <a href="./"><BackHome image={props.avatar}></BackHome></a>
                <Cover_Imageview_Front image={props.image01}></Cover_Imageview_Front>
                <Cover_Imageview_Back image = {props.image02}></Cover_Imageview_Back>
            </Combine_Imageview>
            <ShowcaseDetails>

            <ShowcaseInfo>
                <CompanyInfo>
                    <CompanyLogo image = {props.logo}></CompanyLogo>
                    <CompanyName>{props.name}</CompanyName>
                </CompanyInfo>
                <NumberInfo>
                    <Splitline></Splitline>
                    <Number>{props.number}</Number>
                </NumberInfo>
            </ShowcaseInfo>
            <ShowcaseDescription>
                <ShowcaseTitle>
                    <Title01>{props.title01}</Title01>
                    <Title02>{props.title02}</Title02>
                </ShowcaseTitle>
                <ShowcaseText01>{props.text01}</ShowcaseText01>
                <ShowcaseText02>{props.text02}</ShowcaseText02>
            </ShowcaseDescription>
            

            
        </ShowcaseDetails>
        </InnerContent>
        <Scroll>
            
            <ScrollDown image={props.scroll}></ScrollDown>
        </Scroll>
    </ShowcaseContent>
    </div>

)

export default Showcase